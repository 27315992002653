<template>
    <!--<v-menu
        lazy
        :close-on-content-click="false"
        v-model="picker.menu"
        transition="scale-transition"
        offset-y
        full-width
        :nudge-right="40"
        max-width="290px"
        min-width="290px"
        :disabled="disabled"
    >
        <v-text-field
            slot="activator"
            :label="$t(picker.label)"
            v-model="picker.text"
            :disabled="disabled"
            :append-icon="icon"
            clearable
            :required="required"
            v-bind="attrs"
        />
        <v-date-picker v-model="picker.date" scrollable actions :color="color" header-color="light-green" :locale="locales[$store.state.locale]">
            <v-card-actions>
                <v-btn text small style="min-width: 80px" color="primary" @click="selectToday">{{ $t('Today') }}</v-btn>
                <v-btn text small style="min-width: 80px" color="primary" @click="picker.menu = false">{{ $t('Close') }}</v-btn>
            </v-card-actions>
        </v-date-picker>
    </v-menu>-->

    <v-menu
        v-model="picker.menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="picker.text"
                :label="$t(picker.label)"
                :disabled="disabled"
                :append-icon="icon"
                :required="required"
                @input="onInputChange"
                v-bind="[$attrs, attrs]"
                v-on="on"
                clearable
            />
        </template>
        <v-date-picker
            v-model="picker.date"
            @input="picker.menu = false; onInputChange($event)"
            :type="pickerType"
        />
    </v-menu>

</template>

<script lang="ts" type="typescript">
    import { Vue, Component, Prop, Watch } from "vue-property-decorator";
    import Config from '@/config';
    import moment from 'moment';

    interface Picker {
        date: string,
        text: string;
        label: string,
        menu: boolean;
    }

    @Component
    export default class DatePicker extends Vue {
        @Prop({type: String})
        label: string;

        @Prop({default: ''})
        value: string;

        @Prop({default: false})
        disabled: boolean;

        @Prop({default: 'fas fa-calendar'})
        icon: string;

        @Prop({type: Boolean})
        today: boolean;

        @Prop({type: Boolean, default: false})
        required: boolean;

        @Prop()
        persist: string;

        @Prop({default: 'accent'})
        color: string;

        @Prop({type: Boolean, default: false})
        updateOnSave: boolean;

        @Prop({default: 'date'})
        pickerType: string;

        init: boolean = true;

        picker = <Picker> {
            date: '',
            text: '',
            label: '',
            menu: false,
        };

        locales = Config.locales;

        onInputChange($event) {
            this.$emit('input-change', $event);
        }

        created() {
            this.picker.label = this.label;

            if (this.today) {
                this.selectToday();
            }

            if (!this.updateOnSave) {
                this.$watch('picker.date', () => {
                    this.$emit('input', this.picker.date);
                });
            }

            if (this.persist) {
                if (localStorage.getItem(this.persist)) {
                    this.picker.date = localStorage.getItem(this.persist);
                }
            } else {
                this.onValue(this.value);
            }
        }

        @Watch('value')
        onValue(val: string) {
            let persist = (val: string) => {
                if (this.persist) {
                    localStorage.setItem(this.persist, val);
                }
            };

            if (typeof val === 'string' && val.length) {
                if (moment(val, moment.ISO_8601).isValid()) {
                    this.picker.date = moment(val).format('YYYY-MM-DD');
                    persist(this.picker.date);
                } else if (moment(val, 'YYYY-MM-DD').isValid()) {
                    this.picker.date = val;
                    persist(this.picker.date);
                }
            } else {
                this.picker.date = '';
            }
        }

        get attrs() {
            let output = this.$attrs;
            if (this.required) {
                output['rules'] = <string><unknown>this.$rules.required; // TODO: Testen
            }
            return output;
        }

        selectToday() {
            // Dit moet YYYY-MM-DD zijn, niet aanpassen aub.
            this.picker.date = moment().format('YYYY-MM-DD')
        }

        @Watch('picker.date')
        onDateChange(value: string): void {
            if (typeof value === 'string' && value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/) && moment(value, 'YYYY-MM-DD').isValid()) {
                this.picker.text = moment(value, 'YYYY-MM-DD').format(Config.dateformat);
                this.$emit('input', this.picker.date);
            } else {
                this.picker.text = value;
            }
        }

        @Watch('picker.text')
        onTextChange(value: string) {
            if (typeof value === 'string' && value.match(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/) && moment(value, 'DD-MM-YYYY').isValid()) {
                this.picker.date = moment(value, Config.dateformat).format('YYYY-MM-DD');
            } else {
                this.picker.date = value;
            }
        }
    }
</script>
