<template>
    <v-dialog v-model="infoDialog" max-width="800">
        <v-card v-if="infoDialogData">
            <v-card-title>
                <span class="headline">{{ $t('Job details') }}</span>
            </v-card-title>
            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                        <colgroup>
                            <col style="width: 15%">
                            <col style="width: 85%">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>{{ $t('Job') }}:</td>
                                <td>
                                    <template v-if="infoDialogData.class === 'job.send.email.invoice'">{{ $t('Prepare invoice e-mails') }}</template>
                                    <template v-else-if="infoDialogData.class.indexOf('job.send.email') === 0">{{ $t('Send e-mails') }}</template>
                                    <template v-else>{{ $t('Unknown') }}</template>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('Arguments') }}:</td>
                                <td>
                                    <template v-if="infoDialogData.class === 'job.send.email.invoice'">
                                        <template v-if="infoDialogData.arguments.hasOwnProperty('items')">
                                            {{ $t('Invoice IDs') }}:<br><template v-for="invoiceID in infoDialogData.arguments.items"><span><a :href="host + '/api/invoices/' + encodeURIComponent(invoiceID) + '/render'" target="_blank">{{ invoiceID }}</a></span>, </template>
                                        </template>
                                        <template v-else-if="infoDialogData.arguments.hasOwnProperty('invoiceID')">
                                            {{ $t('Invoice ID') }}:<br><span><a :href="host + '/api/invoices/' + encodeURIComponent(infoDialogData.arguments.invoiceID) + '/render'" target="_blank">{{ infoDialogData.arguments.invoiceID }}</a></span>
                                        </template>
                                    </template>
                                    <template v-else-if="infoDialogData.class.indexOf('job.send.email') === 0">
                                        <template v-if="infoDialogData.arguments.hasOwnProperty('list')">
                                            {{ $t('E-mail numbers') }}:<br><template v-for="emailID in infoDialogData.arguments.list"><span><a href="javascript:" @click="openPreviewDialog(emailID, '')">{{ emailID }}</a></span>, </template>
                                        </template>
                                        <template v-else>
                                            {{ $t('All unsent') }}
                                        </template>
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('Status') }}:</td>
                                <td>
                                    <template v-if="infoDialogData.statusCode === 0 && !infoDialogData.inProgress">{{ $t('Not started') }}</template>
                                    <template v-else-if="infoDialogData.statusCode === 0 && infoDialogData.inProgress">{{ $t('Busy') }}</template>
                                    <template v-else-if="infoDialogData.statusCode === 1"><span class="light-green--text">{{ $t('Successful') }}</span></template>
                                    <template v-else-if="infoDialogData.statusCode === 2"><span class="red--text">{{ $t('Failed') }}</span></template>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('Messages') }}:</td>
                                <td>
                                    <template v-if="infoDialogData.messages">
                                        <list :items="infoDialogData.messages" />
                                    </template>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('Started at') }}:</td>
                                <td class="text-left"><template v-if="infoDialogData.inProgress">{{ infoDialogData.inProgress | moment(dateFormat) }}</template></td>
                            </tr>
                            <tr>
                                <td>{{ $t('Finished at') }}:</td>
                                <td class="text-left"><template v-if="infoDialogData.done">{{ infoDialogData.done | moment(dateFormat) }}</template></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <template v-if="infoDialogData.statusCode === 0 && infoDialogData.inProgress">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ $t('Mark as') }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="markAs(2)">
                                <v-list-item-title>{{ $t('Failed') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="markAs(1)">
                                <v-list-item-title>{{ $t('Successful') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <v-spacer />
                <v-btn color="primary" style="min-width: 100px" text @click.native="closeInfoDialog()">
                    {{ $t('Close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import List from "@/components/list.vue";
import Config from "@/config";
import Request from "@/lib/apollo/request";
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';

@Component({
    components: {List}
})
export default class JobInfo extends Vue {
    @Prop({type: Number})
    id: number;

    host: string = Config.api.host;
    dateFormat: string = Config.datetimeformat;

    infoDialog: boolean = false;
    infoDialogData: any = {};

    @Watch('id')
    openInfoDialog(id: number) {
        Request.make('/job/get/' + encodeURIComponent(id), Request.GET).then((response: any) => {
            if (response.items) {
                this.infoDialogData = response.items[0];
                this.infoDialog = true;
            }
        });
    }
    closeInfoDialog() {
        this.infoDialog = false;
        this.infoDialogData = null;
    }
    markAs(statusCode: number) {
        Request.make('/job/mark/' + encodeURIComponent(this.id), Request.POST, {
            status: statusCode,
        }).then((response: any) => {
            if (response.status === 'OK') {
                this.closeInfoDialog();
                this.$emit('closed');
            }
        });
    }
}
</script>

<style scoped lang="scss">

</style>